import React, { FC, useRef } from "react";
import ReactResizeDetector from "react-resize-detector";
import { graphql } from "gatsby";
import Img, { FluidObject } from "gatsby-image";
import { IndexPageQuery } from "../../graphql-types";

import { colors, fontStacks, borders } from "../global-style";
import { BackgroundBlockAbs } from "../components/backgroundblockabs";
import { CenteredContent } from "../components/centeredcontent";
import { Card } from "../components/card";
import { transparentize } from "polished";

export const query = graphql`
  query IndexPage {
    image: file(
      relativePath: {
        eq: "man-holding-white-teacup-in-front-of-gray-laptop-842567.png"
      }
    ) {
      id
      childImageSharp {
        fluid(webpQuality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "man-with-headphones-facing-computer-monitor-845451.png"
      }
    ) {
      id
      childImageSharp {
        fluid(webpQuality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    image3: file(relativePath: { eq: "logo-popken.png" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    wp {
      page(id: "/home/", idType: URI) {
        title
        homeCustomFields {
          intro {
            image {
              sourceUrl
              localFile {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 1200) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }

            title
            content
            link {
              title
              url
            }
          }
          bluecontent {
            subtitle
            title
          }
          kopje {
            content
            subtitle
            title
            link {
              linkurl
              linktext
            }
            img {
              sourceUrl
              localFile {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 1200) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          centeredcontent {
            content
            fieldGroupName
            reverse
            title {
              subtitle
              title
            }
            link {
              linkUrl
              linktext
            }
            img {
              id
              sourceUrl
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1000, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          lichtbluecenteredcontent {
            title
            subtitle
            content
            link {
              linktext
              linkurl
            }
            img {
              id
              sourceUrl
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1000, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }

      investments(first: 9999) {
        edges {
          node {
            title
            uri
            investmentCustomFields {
              description {
                subtitle
                country
                sector
                since
                logo {
                  sourceUrl
                  localFile {
                    childImageSharp {
                      fluid(quality: 90, maxWidth: 400) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const IndexPage: FC<{ data: IndexPageQuery }> = props => {
  const textContainer1 = useRef<any>(null);
  const textContainer2 = useRef<any>(null);
  const backgroundBlock = useRef<any>(null);
  const headDiv = useRef<any>(null);
  const leftHighlight = useRef<any>(null);
  const rightHighlight = useRef<any>(null);
  const ulElement = useRef<any>(null);

  return (
    <ReactResizeDetector handleWidth={true}>
      {() => {
        const textContainer1X = textContainer1.current
          ? textContainer1.current.getBoundingClientRect().x
          : 0;

        const textContainer2X = textContainer2.current
          ? textContainer2.current.getBoundingClientRect().x
          : 0;

        const eHeight = leftHighlight.current
          ? leftHighlight.current.getBoundingClientRect().height
          : 0;

        const eX = leftHighlight.current
          ? leftHighlight.current.getBoundingClientRect().x
          : 0;

        const rightHighlightHeight = rightHighlight.current
          ? rightHighlight.current.getBoundingClientRect().height
          : 0;

        const ulElementHeight = ulElement.current
          ? ulElement.current.getBoundingClientRect().height
          : 0;

        const textContainerWrapped = textContainer1X === textContainer2X;

        return (
          <>
            <div
              id="scroll-1"
              ref={headDiv}
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                marginBottom: "12rem",
              }}
              css={`
                @media (max-width: 1024px) {
                  .header-image-cover {
                    width: 100% !important;
                    height: 100% !important;
                    right: 0rem !important;
                  }
                  .headerImage {
                    opacity: 0.47;
                    border-radius: 0rem !important;
                  }
                  .topDivContainer {
                    padding-top: 8.5rem !important;
                  }
                }
                @media (max-width: 425px) {
                  .topDivContainer {
                    padding-top: 2rem !important;
                  }
                }
              `}
            >
              <div
                className="topDivContainer"
                style={{
                  flexBasis: "83rem",
                  paddingTop: "1.5rem",
                  border: borders.red,
                }}
              >
                <div
                  style={{
                    margin: "0 1.5rem",
                  }}
                >
                  <div
                    style={{
                      marginBottom: "2rem",
                      lineHeight: "2.75rem",
                      display: "flex",
                      overflow: "hidden",
                      width: "100%",
                      maxWidth: "20rem",
                      border: borders.green,
                    }}
                  >
                    <div
                      style={{
                        fontFamily: fontStacks.merriweather,
                        fontSize: "2rem",
                        margin: "0.5rem",
                      }}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: props.data.wp.page?.homeCustomFields?.intro
                            ?.title as string,
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div ref={backgroundBlock} style={{ flexGrow: 1, zIndex: 5 }}>
                  <BackgroundBlockAbs
                    contentText={
                      props.data.wp.page?.homeCustomFields?.intro
                        ?.content as string
                    }
                    linkText={
                      props.data.wp.page?.homeCustomFields?.intro?.link
                        ?.title as string
                    }
                    linkUrl={
                      props.data.wp.page?.homeCustomFields?.intro?.link
                        ?.url as string
                    }
                  />
                </div>
              </div>

              <div
                className="header-image-cover"
                style={{ position: "absolute", right: "3rem", width: "50%" }}
              >
                <Img
                  className="headerImage"
                  fluid={
                    props.data.wp.page?.homeCustomFields?.intro?.image
                      ?.localFile?.childImageSharp?.fluid as FluidObject
                  }
                  style={{
                    flexBasis: "50%",
                    maxHeight: "40rem",
                    borderRadius: "3rem 0rem 0rem 0rem",
                    position: "absolute",
                    width: "100%",
                    zIndex: -5,
                  }}
                />
              </div>
            </div>

            <div style={{ position: "relative" }}>
              <span
                style={{ position: "absolute", top: "-200px" }}
                id="scroll-2"
              />
              {props.data.wp.page?.homeCustomFields?.centeredcontent?.map(
                (edge, i) => {
                  return (
                    <CenteredContent
                      key={i}
                      img={
                        edge?.img?.localFile?.childImageSharp
                          ?.fluid as FluidObject
                      }
                      subtitle={edge?.title?.subtitle as string}
                      title={edge?.title?.title as string}
                      content={edge?.content as string}
                      link={edge?.link?.linkUrl as string}
                      linkText={edge?.link?.linktext as string}
                      reverse={edge?.reverse as boolean}
                    />
                  );
                }
              )}
            </div>

            <div
              style={{
                overflow: "hidden",
                display: "flex",
                justifyContent: "center",
                position: "relative",
              }}
            >
              <div
                style={{
                  right: 0,
                  position: "absolute",
                  backgroundColor: colors.aluminum,
                  zIndex: -1,
                  height: `calc(${eHeight}px - 12rem)`,
                  minWidth: `calc(${
                    typeof screen !== "undefined"
                      ? `${screen.width - eX}px`
                      : "100vw"
                  } + 5rem)`,
                  borderRadius: "0 0 0 2.5rem",
                }}
              ></div>

              <div
                ref={leftHighlight}
                style={{ paddingTop: "5rem", paddingBottom: "5rem" }}
              >
                <CenteredContent
                  img={
                    props.data.wp.page?.homeCustomFields
                      ?.lichtbluecenteredcontent?.img?.localFile
                      ?.childImageSharp?.fluid as FluidObject
                  }
                  title={
                    props.data.wp.page?.homeCustomFields
                      ?.lichtbluecenteredcontent?.title || ""
                  }
                  subtitle={
                    props.data.wp.page?.homeCustomFields
                      ?.lichtbluecenteredcontent?.subtitle || ""
                  }
                  content={
                    props.data.wp.page?.homeCustomFields
                      ?.lichtbluecenteredcontent?.content || ""
                  }
                  link={
                    props.data.wp.page?.homeCustomFields
                      ?.lichtbluecenteredcontent?.link?.linkurl || ""
                  }
                  linkText={
                    props.data.wp.page?.homeCustomFields
                      ?.lichtbluecenteredcontent?.link?.linktext || ""
                  }
                />
              </div>
            </div>

            <div style={{ position: "relative" }}>
              <span
                style={{ position: "absolute", top: "-200px" }}
                id="scroll-3"
              />
              <CenteredContent
                reverse
                img={
                  props.data.wp.page?.homeCustomFields?.kopje?.img?.localFile
                    ?.childImageSharp?.fluid as FluidObject
                }
                title={props.data.wp.page?.homeCustomFields?.kopje?.title || ""}
                subtitle={
                  props.data.wp.page?.homeCustomFields?.kopje?.subtitle || ""
                }
                content={
                  props.data.wp.page?.homeCustomFields?.kopje?.content || ""
                }
                link={
                  props.data.wp.page?.homeCustomFields?.kopje?.link?.linkurl ||
                  ""
                }
                linkText={
                  props.data.wp.page?.homeCustomFields?.kopje?.link?.linktext ||
                  ""
                }
              />
            </div>

            <div
              style={{
                overflow: "hidden",
                display: "flex",
                justifyContent: "center",
                position: "relative",
              }}
            >
              <span
                style={{ position: "absolute", top: "-200px" }}
                id="scroll-5"
              />

              <div
                style={{
                  left: 0,
                  position: "absolute",
                  backgroundColor: colors.rhino,
                  zIndex: -1,
                  height: rightHighlightHeight,
                  minWidth: `calc(${
                    typeof screen !== "undefined"
                      ? `${screen.width - eX}px`
                      : "100vw"
                  } + 5rem)`,
                  borderRadius: "0 0 2.5rem 0",
                }}
              ></div>

              <div
                ref={rightHighlight}
                style={{
                  padding: "3.5rem 0",
                  marginBottom: "0rem",
                  width: "100%",
                  border: borders.red,
                  maxWidth: "83rem",
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "flex",
                  flexDirection: "column",
                  color: colors.superiorWhite,
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    margin: "1.5rem",
                    border: borders.green,
                  }}
                >
                  <div
                    className="sm-center"
                    style={{
                      marginBottom: "0.5rem",
                      fontFamily: fontStacks.merriweather,
                      fontSize: "2rem",
                      lineHeight: "2.5rem",
                    }}
                    dangerouslySetInnerHTML={{
                      __html:
                        props.data.wp.page?.homeCustomFields?.bluecontent
                          ?.title || "",
                    }}
                  />

                  <div
                    className="sm-center"
                    style={{ marginBottom: "0.5rem" }}
                    dangerouslySetInnerHTML={{
                      __html:
                        props.data.wp.page?.homeCustomFields?.bluecontent
                          ?.subtitle || "",
                    }}
                  />
                </div>

                <div
                  style={{
                    margin: "1.5rem 0rem 1.5rem 1.5rem",
                    border: borders.green,
                  }}
                >
                  <div
                    style={{
                      padding: "1.5rem 0",
                      display: "flex",
                      overflowX: "auto",
                      WebkitOverflowScrolling: "touch",
                      scrollSnapType: "x mandatory",
                    }}
                    css={`
                      a + a {
                        margin-left: 3rem;
                      }
                      .card {
                        scroll-snap-align: start;
                        background-color: ${colors.aluminum};
                        min-width: 14rem;
                        min-height: 18rem;
                        transition: 0.25s;
                      }

                      .card:hover,
                      .card:focus {
                        transform: translate(0px, -10px);
                        box-shadow: 15px 15px 20px
                          ${transparentize(0.33, colors.carbon)};
                      }
                    `}
                  >
                    {props.data.wp.investments?.edges?.map(
                      (edge, i) =>
                        edge?.node && (
                          <Card
                            key={i}
                            img={
                              edge.node.investmentCustomFields?.description.logo
                                .localFile?.childImageSharp
                                ?.fluid as FluidObject
                            }
                            link={`/${edge.node.uri}`}
                            title={edge.node.title as string}
                            subtitle={
                              edge.node.investmentCustomFields?.description
                                ?.subtitle as string
                            }
                            country={
                              edge.node.investmentCustomFields?.description
                                ?.country as string
                            }
                            sector={
                              edge.node.investmentCustomFields?.description
                                ?.sector as string
                            }
                            since={
                              edge.node.investmentCustomFields?.description
                                ?.since as string
                            }
                          />
                        )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      }}
    </ReactResizeDetector>
  );
};

export default IndexPage;
