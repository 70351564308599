import React, { FC, Fragment, useEffect, useState, useRef } from "react";
import Img, { FluidObject } from "gatsby-image";
import { Link } from "gatsby";
import DOMpurify from "dompurify";

import { fontStacks, colors, borders } from "../global-style";
import ReactResizeDetector from "react-resize-detector";

interface IProps {
  img: FluidObject | FluidObject[];
  title: String;
  subtitle: String;
  content: string;
  link: any;
  linkText: any;
  reverse?: boolean;
}

export const CenteredContent: FC<IProps> = props => {
  const {
    img,
    link,
    content,
    title,
    subtitle,
    reverse,
    linkText,
  }: IProps = props;

  // ReactHooks to make a state veriable
  let [cleanContent, setCleanContent] = useState("");

  // This UseEffect is getting called on mount. so only once!
  // UseEffect hook to load sanitize the content
  // Update the cleanContent State
  // @todo Update Allowed tags with the WYSIWYG toolbox
  useEffect(() => {
    setCleanContent(
      DOMpurify.sanitize(content, {
        ALLOWED_TAGS: ["br", "strong", "italic", "ul", "li"],
      })
    );
  }, [props.content]);

  const leftEl = useRef<any>(null);
  const rightEl = useRef<any>(null);

  return (
    <ReactResizeDetector handleWidth={true}>
      {() => {
        const leftX = leftEl.current
          ? leftEl.current.getBoundingClientRect().x
          : 0;

        const rightX = rightEl.current
          ? rightEl.current.getBoundingClientRect().x
          : 0;

        const isWrapped = leftX === rightX;
        return (
          <div
            className="centered-content"
            style={{
              overflow: "hidden",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                marginBottom: "12rem",
                width: "100%",
                border: borders.red,
                maxWidth: "83rem",
                marginLeft: "auto",
                marginRight: "auto",
                display: "flex",
                flexDirection: reverse ? "row-reverse" : "row",
                flexWrap: "wrap",
                justifyContent: isWrapped ? "center" : "space-between",
              }}
            >
              <div
                ref={leftEl}
                style={{
                  margin: "0.5rem 1.5rem",
                  flexBasis: "30rem",
                  border: borders.green,
                }}
              >
                <Img fluid={img} />
              </div>
              <div
                ref={rightEl}
                style={{
                  margin: "0.5rem 1.5rem",
                  flexBasis: "30rem",
                  border: borders.green,
                }}
              >
                <div
                  // className="sm-center"
                  style={{
                    marginBottom: "1rem",
                    fontFamily: fontStacks.merriweather,
                    fontSize: "2rem",
                    lineHeight: "2.5rem",
                  }}
                >
                  {title}
                </div>
                <div className="sm-center" style={{ marginBottom: "0.5rem" }}>
                  {subtitle}
                </div>
                <div
                  style={{ marginBottom: "2rem" }}
                  dangerouslySetInnerHTML={{ __html: cleanContent }}
                ></div>
                {link && (
                  <Link
                    style={{
                      padding: "0.5rem 2rem",
                      backgroundColor: colors.sandal,
                      color: colors.superiorWhite,
                    }}
                    to={link}
                    dangerouslySetInnerHTML={{ __html: linkText }}
                  />
                )}
              </div>
            </div>
          </div>
        );
      }}
    </ReactResizeDetector>
  );
};
